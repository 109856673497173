import React, {memo, useCallback} from 'react';
import {navigate} from 'gatsby';
import useSetUser from '../../hooks/useSetUser';
import {lockClosedIcon, twofa} from '../../images/svgIcons';
import {getPackageInfo, returnDashUrl} from '../../helpers';
import './styles.scss';

const handleChangeTwoFactor = async () => {
  window.location.href = returnDashUrl() + 'profile/security';
};

const ChangeToFactor = ({state}: any): JSX.Element => {
  const [getUser] = useSetUser(null, () => {
    localStorage.setItem('fromLogonOrRegistrationPage', 'true');
    navigate(getPackageInfo() ? '/pricing/checkout' : state?.navigatePath ? state?.navigatePath : '/pricing/checkout');
  });
  const redirects = useCallback(() => {
    if (getPackageInfo()) {
      localStorage.setItem('navState', '/pricing/checkout/');
      getUser();
    } else if (state?.navigatePath) {
      localStorage.setItem('navState', state?.navigatePath);
      getUser();
    } else {
      window.location.href = returnDashUrl();
    }
  }, [getPackageInfo, state]);

  return (
    <div className="text-center">
      <div className="d-flex mb-3 justify-content-center">{twofa}</div>
      <h4 className="text-center enable-text">Enable two-factor authentication</h4>

      <div className="submit-block">
        <button className="cancel-btn mr-2" onClick={redirects}>
          Close
        </button>
        <button className="submit-btn" onClick={handleChangeTwoFactor}>
          Get started
        </button>
      </div>
    </div>
  );
};

export default memo(ChangeToFactor);
