import React, {FC, useEffect, useRef, useState} from 'react';
//@ts-ignore
import {useMediaQuery} from 'react-responsive';
import './style.scss';
interface IProps {
  length?: number;
  setCode?: any;
  setError?: any;
  error?: boolean;
}
const VerifyCode: FC<IProps> = ({length = 6, setCode, setError, error}) => {
  const [code, setCodeData] = useState<any>({});
  const [mobileCode, setMobileCodeData] = useState<string>('');
  const elemRefs: any = [];
  const handleEnterKeyPress = (e: any, index: any) => {
    setError('');
    if (e.key === 'Backspace' || e.key === 'Delete') {
      !e.target.value && index && elemRefs[index - 1]?.current?.focus();
      return;
    }
    if (e.key === 'ArrowRight') {
      elemRefs[index + 1]?.current?.focus();
      return;
    }
    if (e.key === 'ArrowLeft') {
      elemRefs[index - 1]?.current?.focus();
      return;
    }
    if (!index && e.key === 'v' && e.ctrlKey) {
      const data = e.target.value.split('');
      const a = {...code};
      data.map((item: string, i: number) => {
        //@ts-ignore
        if (i < length) a[i] = item;
      });
      //Object.values(a).join('').length === length && action && action(Object.values(a).join(''));
      setCodeData(a);
      return;
    }
    code[0] && code[0].length > 1 && setCodeData({...code, '0': code[0][code[0].length - 1]});
    (parseInt(e.key) || parseInt(e.key) === 0) && elemRefs[index + 1]?.current?.focus();
    // index + 1 === length && Object.values(code).join('').length === length && action && action();
  };
  Array(length)
    .join('.')
    .split('.')
    .map(() => {
      const ref = useRef(null);
      elemRefs.push(ref);
    });

  useEffect(() => {
    const data = Object.values(code).join('');
    setCode(data && data.length === length ? data : '');
    data.length && setError('');
  }, [code]);
  useEffect(() => {
    if (mobileCode) setError('');
    if (mobileCode && mobileCode.length === length) {
      setCode(mobileCode);
      // action && action(mobileCode);
    }
  }, [mobileCode]);
  useEffect(() => {
    if (error) {
      setCodeData({});
      setMobileCodeData('');
      elemRefs[0]?.current?.focus();
    }
  }, [error]);

  const handelInputChange = (val: string, i: number) => {
    if (val && parseInt(val.trim()) !== 0 && !parseInt(val.trim())) return;
    if (!i && val.length > 1) {
      if (val[0] === code[0]) val = val.slice(1);
      else if (val[val.length - 1] === code[0]) val = val.slice(0, val.length - 1);
      if (val.length > 1) {
        const a = {...code};
        val.split('').map((item: string, i: number) => {
          //@ts-ignore
          if (i < length) a[i] = item;
        });
        //Object.values(a).join('').length === length && action && action(Object.values(a).join(''));
        setCodeData(a);
        return;
      }
    }
    setCodeData({...code, [`${i}`]: val});
    //i + 1 === length && Object.values({...code, [`${i}`]: val}).length === length && action && action();
  };
  const isMobile = useMediaQuery({maxWidth: 991});
  return (
    <div className=" verify-code">
      {isMobile ? (
        <input
          value={mobileCode}
          onChange={(e: any) => setMobileCodeData(e.target.value.replaceAll(' ', ''))}
          type="text"
          name={`code-${0}`}
          maxLength={length}
          className="code-input long"
          tabIndex={0}
          autoFocus
        />
      ) : (
        <>
          {Array(length)
            .join('.')
            .split('.')
            .map((x: any, i: number) => {
              return (
                <>
                  <input
                    value={code[i] || ''}
                    onChange={(e: any) => handelInputChange(e.target.value.replace(' ', ''), i)}
                    type="text"
                    name={`code-${i}`}
                    maxLength={i ? 1 : length + 2}
                    className="code-input"
                    onKeyUp={(e: any) => handleEnterKeyPress(e, i)}
                    tabIndex={i}
                    ref={elemRefs[i] ? elemRefs[i] : null}
                    autoFocus={!i}
                    autoComplete="off"
                  />
                </>
              );
            })}
        </>
      )}
    </div>
  );
};

export default VerifyCode;
