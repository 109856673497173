import React, {FC, useCallback, memo} from 'react';
import {eventStop} from '../../../helpers';
import {checkedIcon, checkIcon} from '../../../images/svgIcons';
import './styles.scss';

const Checkbox: FC<{
  checked: boolean;
  disabled?: boolean;
  click: (arg0: boolean) => void;
}> = ({checked, disabled, click}) => {
  const handelClick = useCallback(
    (e?: any) => {
      if (!disabled) {
        eventStop(e);
        click(!checked);
      }
    },
    [disabled, click, checked],
  );

  return (
    <div className="noselect">
      {checked ? (
        <div className={`custom-checkbox checked ${disabled ? 'disabled' : ''}`} onClick={handelClick}>
          {disabled ? checkedIcon : checkIcon}
        </div>
      ) : (
        <div className={`custom-checkbox ${disabled ? 'disabled' : ''}`} onClick={handelClick} />
      )}
    </div>
  );
};

export default memo(Checkbox);
