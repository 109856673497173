import React, {useState, useEffect, useContext, FC} from 'react';
import { UserContext } from '../../contexts/user-context';
import ForgotPassword from '../../components/forgot-password';
import SimpleLayout from '../../components/layout_simple';
import Login from '../../components/login';
import {arrowLeft} from '../../images/svgIcons';
import './styles.scss';
import { navigate } from 'gatsby';

const LoginPage: FC<any> = ({location}) => {
  const { state = {} } = location;
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const {user} = useContext(UserContext);

  const onBackBtnClick = () => {
    setShowForgotPassword(false);
  };
  useEffect(() => {
    if (user && user.id) {
      navigate('/');
    }
  }, [user]);

  return (
    <SimpleLayout isClickable>
      {!showForgotPassword ? (
        <Login setShowForgotPassword={setShowForgotPassword} state={state}/>
      ) : (
        <div className="forgot-password-section">
          <span className="go-back" onClick={onBackBtnClick}>
            {arrowLeft}
          </span>
          <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
        </div>
      )}
    </SimpleLayout>
  );
};

export default LoginPage;
